import { Project, UserStoryWithPersona } from '../interfaces/project'

export const getProjectName = (project?: Project) => {
  if (!project) {
    return ''
  }

  return project.name || 'Untitled Project'
}

export const getUserstoryDescription = (userstory: UserStoryWithPersona) => {
  return userstory.description || 'Storiz description'
}
