import React from 'react'
import Link from 'next/link'
import useSWR from 'swr'
import plur from 'plur'

import { ProjectMetadata } from '../../interfaces/project'
import { getProjectName } from '../../utils/project'
import Card from '../../components/Card'
import Button from '../../components/Button'
import FigmaLogo from '../../components/FigmaLogo'

const Projects = () => {
  const { data: projects } = useSWR<ProjectMetadata[]>('/api/projects')

  if (!projects) {
    return null
  }

  return (
    <>
      <div className="bg-white flex items-center px-16 py-12">
        <div className="flex-1">
          <h1 className="font-semibold text-2xl">Welcome to userstoriz!</h1>
          <h2 className="text-secondary text-lg mt-3">
            You have {projects.length} specifications{' '}
            {plur('project', projects.length)}
          </h2>
        </div>
        <Link href="/projects/new">
          <a>
            <Button appearance="primary">Create new specifications</Button>
          </a>
        </Link>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-16 py-5">
        {projects.map((project) => (
          <Link key={project.id} href={`/projects/${project.id}`}>
            <a>
              <Card
                className="transform transition-transform duration-200 hover:-translate-y-1"
                withPadding={false}
              >
                <div className="relative flex h-40 border-b items-center justify-center rounded-t overflow-hidden">
                  {project.figmaThumbnailUrl ? (
                    <img
                      src={project.figmaThumbnailUrl}
                      className="object-contain object-center"
                      alt={project.figmaFileName}
                      onError={(event) =>
                        (event.currentTarget.src = '/images/placeholder.svg')
                      }
                    />
                  ) : (
                    <img
                      src="/images/placeholder.svg"
                      className="object-contain object-center"
                    />
                  )}
                </div>
                <div className="px-4 py-3">
                  <h2 className="text-sm font-medium mb-2">
                    {getProjectName(project)}
                  </h2>
                  <div className="flex text-xs space-x-2 items-center">
                    <div className="bg-gray-100 rounded p-1 whitespace-nowrap">
                      {project.userstoriesCount} storiz
                    </div>
                    {project.figmaFileName ? (
                      <div className="flex items-center bg-gray-100 rounded p-1 min-w-0">
                        <FigmaLogo />
                        <span className="ml-1 truncate">
                          {project.figmaFileName}
                        </span>
                      </div>
                    ) : (
                      <div className="text-gray-500">No Figma project</div>
                    )}
                  </div>
                </div>
              </Card>
            </a>
          </Link>
        ))}
      </div>
    </>
  )
}

export default Projects
