import React, { SVGProps } from 'react'

const FigmaLogoBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <rect width="16" height="16" fill="#30333B" rx="3"></rect>
    <g clipPath="url(#clip0_figmaLogoBlack)">
      <path
        fill="#0ACF83"
        d="M6.667 13c.92 0 1.666-.747 1.666-1.667V9.666H6.667a1.667 1.667 0 000 3.334z"
      ></path>
      <path
        fill="#A259FF"
        d="M5 8c0-.92.747-1.667 1.667-1.667h1.666v3.334H6.667c-.92 0-1.666-.747-1.666-1.667z"
      ></path>
      <path
        fill="#F24E1E"
        d="M5.001 4.667c0-.92.746-1.667 1.666-1.667h1.667v3.333H6.667c-.92 0-1.666-.746-1.666-1.666z"
      ></path>
      <path
        fill="#FF7262"
        d="M8.333 3H10a1.667 1.667 0 010 3.333H8.333V3z"
      ></path>
      <path
        fill="#1ABCFE"
        d="M11.666 8a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_figmaLogoBlack">
        <path
          fill="#fff"
          d="M0 0h6.667v10H0z"
          transform="translate(5 3)"
        ></path>
      </clipPath>
    </defs>
  </svg>
)

export default FigmaLogoBlack
