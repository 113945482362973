import React, { SVGProps } from 'react'

const FigmaLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 11 16"
    {...props}
  >
    <g clipPath="url(#clip0_figmaLogo)">
      <path
        fill="#0ACF83"
        d="M2.667 16a2.667 2.667 0 002.666-2.667v-2.666H2.667a2.667 2.667 0 000 5.333z"
      ></path>
      <path
        fill="#A259FF"
        d="M.001 8a2.667 2.667 0 012.666-2.667h2.666v5.334H2.667A2.667 2.667 0 01.001 8z"
      ></path>
      <path
        fill="#F24E1E"
        d="M.001 2.667A2.667 2.667 0 012.667 0h2.666v5.333H2.667A2.667 2.667 0 01.001 2.667z"
      ></path>
      <path
        fill="#FF7262"
        d="M5.333 0h2.666a2.667 2.667 0 010 5.333H5.333V0z"
      ></path>
      <path
        fill="#1ABCFE"
        d="M10.665 8A2.667 2.667 0 018 10.667a2.667 2.667 0 010-5.334A2.667 2.667 0 0110.665 8z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_figmaLogo">
        <path fill="#fff" d="M0 0h10.667v16H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default FigmaLogo
