import React from 'react'
import { NextPage } from 'next'
import Head from 'next/head'

import useUser from '../utils/hooks/useUser'
import Layout from '../components/Layout'
import Projects from '../containers/Projects'

const HomePage: NextPage = () => {
  const { user } = useUser({ redirectTo: '/login' })

  if (!user?.isLoggedIn) {
    return <Layout withSideNav={false} />
  }

  return (
    <Layout pageName="All Projects">
      <Head>
        <title>Projects | userstoriz</title>
      </Head>
      <Projects />
    </Layout>
  )
}

export default HomePage
