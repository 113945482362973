import React, { FC, HTMLAttributes } from 'react'
import cx from 'classnames'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  withPadding?: boolean
}

const Card: FC<CardProps> = ({ withPadding = true, className, ...props }) => (
  <div
    className={cx(
      'flex flex-col bg-white shadow-md rounded text-gray-900',
      { 'p-8': withPadding },
      className
    )}
    {...props}
  />
)

export default Card
