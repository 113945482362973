import React, {
  ButtonHTMLAttributes,
  ElementType,
  ForwardedRef,
  JSXElementConstructor,
  forwardRef,
  useCallback,
} from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

import FigmaLogo from '../FigmaLogo'
import FigmaLogoBlack from '../FigmaLogoBlack'

export type ButtonAppearance = 'default' | 'primary'

export type ButtonIntent = 'none' | 'success' | 'warning' | 'danger'

interface ButtonProps<T extends ElementType = 'button'>
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
  as?: T | JSXElementConstructor<any>
  appearance?: ButtonAppearance
  intent?: ButtonIntent
  size?: 'big' | 'default' | 'small'
  isFullWidth?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  icon?: IconProp | 'figma' | 'figma-black'
}

const getVariationClasses = (
  appearance: ButtonAppearance,
  intent: ButtonIntent,
  isDisabled?: boolean
) => {
  switch (appearance) {
    case 'primary': {
      return cx('text-white border', {
        'bg-primary border-primary': intent === 'none',
        'hover:bg-primary-dark focus:ring-primary':
          intent === 'none' && !isDisabled,
        'bg-green-500 border-green-500': intent === 'success',
        'hover:bg-green-600 focus:ring-green-500':
          intent === 'success' && !isDisabled,
        'bg-orange-400 border-orange-400': intent === 'warning',
        'hover:bg-orange-500 focus:ring-orange-400':
          intent === 'warning' && !isDisabled,
        'bg-red-500 border-red-500': intent === 'danger',
        'hover:bg-red-600 focus:ring-red-500':
          intent === 'danger' && !isDisabled,
      })
    }
    case 'default': {
      return cx('bg-white border border-gray-400', {
        'hover:border-opacity-20 hover:bg-opacity-5': !isDisabled,
        'text-gray-600': intent === 'none',
        'hover:text-primary hover:bg-primary hover:border-primary focus:ring-primay':
          intent === 'none' && !isDisabled,
        'text-green-500': intent === 'success',
        'hover:bg-green-500 hover:border-green-500 focus:ring-green-500':
          intent === 'success' && !isDisabled,
        'text-orange-400': intent === 'warning',
        'hover:bg-orange-400 hover:border-orange-400 focus:ring-orange-400':
          intent === 'warning' && !isDisabled,
        'text-red-500': intent === 'danger',
        'hover:bg-red-500 hover:border-red-500 focus:ring-red-500':
          intent === 'danger' && !isDisabled,
      })
    }
  }
}

const Button = <T extends ElementType = 'button'>(
  props: ButtonProps<T>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    children,
    as: As = 'button',
    className,
    appearance = 'default',
    intent = 'none',
    size = 'default',
    isFullWidth,
    isDisabled,
    isLoading,
    icon,
    ...rest
  } = props

  const renderIcon = useCallback(() => {
    if (isLoading) {
      return <FontAwesomeIcon icon={faSpinnerThird} spin className="mr-2" />
    }

    if (!icon) {
      return null
    }

    if (icon === 'figma-black') {
      return <FigmaLogoBlack className="mr-2 text-base" />
    }

    if (icon === 'figma') {
      return <FigmaLogo className="mr-2 text-base" />
    }

    return <FontAwesomeIcon icon={icon} className="mr-2" />

    // @ts-ignore
  }, [isLoading, icon])

  return (
    <As
      ref={ref}
      disabled={isDisabled || isLoading}
      className={cx(
        'inline-flex items-center justify-center flex-shrink-0 font-heading font-semibold text-sm rounded shadow-xs transition duration-200 focus:outline-none focus:ring-2 focus:ring-opacity-50',
        getVariationClasses(appearance, intent, isDisabled || isLoading),
        {
          'h-12 px-5': size === 'big',
          'h-10 px-5': size === 'default',
          'h-8 px-4': size === 'small',
          'w-full': isFullWidth,
          'opacity-40 cursor-not-allowed': isDisabled || isLoading,
        },
        className
      )}
      {...rest}
    >
      {renderIcon()}
      {children}
    </As>
  )
}

export default forwardRef(Button)
